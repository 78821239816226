import { FC } from "react";

import ABSliderBox from "components/AbTestDiscountVsInstallments/slider/ABSliderBox";
import {
  mockVariant,
  slider,
} from "components/AbTestDiscountVsInstallments/data";

type ABSliderProps = {
  variantAB?: string;
  elementsCount: number;
};

const sliderMap = {
  A: slider.A,
  B: slider.B,
};

const ABSlider: FC<ABSliderProps> = ({ variantAB, elementsCount }) => {
  const variant = mockVariant ? mockVariant : variantAB;
  const data = variant ? sliderMap[variant] : undefined;

  return data ? (
    <ABSliderBox {...data} itemNumber={1} elementsCount={elementsCount} />
  ) : (
    <></>
  );
};
export default ABSlider;
