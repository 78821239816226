import { FC, useState } from "react";

import { useTranslations } from "next-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material";

import SlideNavigation from "components/MainCarousel/SlideNavigation";

import { colorPrimary700, colorText50 } from "theme/colors";
import { ScissorsIcon } from "theme/SvgIcons";

type ABSliderBoxProps = {
  image: string;
  backgroundImg1: string;
  backgroundImg2: string;
  imageMobile: string;
  backgroundImg1Mobile: string;
  backgroundImg2Mobile: string;
  circleColor1: string;
  circleColor2: string;
  textLine1: string;
  textLine2: string;
  textLine3: string;
  textLine4: string;
  buttonText: string;
  buttonURL: string;
  discountCode: string;
  itemNumber: number;
  elementsCount: number;
};

const ABSliderBox: FC<ABSliderBoxProps> = ({
  image,
  backgroundImg1,
  backgroundImg2,
  imageMobile,
  backgroundImg1Mobile,
  backgroundImg2Mobile,
  itemNumber,
  elementsCount,
  buttonURL,
  discountCode,
  buttonText,
  textLine1,
  textLine2,
  textLine3,
  textLine4,
  circleColor1,
}) => {
  const t = useTranslations("common");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(discountCode).then(() => setIsCopied(true));
  };

  return (
    <Box
      onClick={() =>
        buttonURL
          ? (document.location.href = buttonURL)
          : console.error("no-slide-url")
      }
    >
      <Box
        height="610px"
        sx={{
          cursor: "pointer",
          background: circleColor1,
          display: "relative",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            zIndex: 0,
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            zIndex: 1,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImg2})`,
            backgroundSize: "fill",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            height: "auto",
            width: { sm: "100%", md: "100%", lg: "95%", xl: "90%" },
          }}
        />
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            zIndex: 2,
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImg1})`,
            backgroundSize: "fill",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: { sm: "60%", md: "60%", lg: "60%", xl: "63%" },
          }}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: 0,
            position: "absolute",
            right: 0,
            top: 0,
            backgroundImage: `url(${imageMobile})`,
            backgroundSize: "contain",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: 1,
            position: "absolute",
            bottom: "0",
            backgroundImage: `url(${backgroundImg1Mobile})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            height: "80%",
            width: "140%",
          }}
        />
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
            zIndex: 2,
            position: "absolute",
            bottom: "0",
            backgroundImage: `url(${backgroundImg2Mobile})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            height: "100%",
            width: "100%",
          }}
        />
        <Container
          maxWidth="xl"
          className={"fullHeight"}
          sx={{ position: "relative", zIndex: 3 }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            textAlign={"left"}
            px={"40px"}
            pt={"40px"}
            pb={"80px"}
            className={"fullHeight"}
          >
            <>
              {/* *** DESKTOP view start *** */}
              <Box sx={{ display: { xs: "none", sm: "block" }, zIndex: 3 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box
                    sx={{
                      width: { sm: "55%", md: "60%", lg: "60%", xl: "55%" },
                    }}
                  >
                    {/* DESKTOP CODE VALID TO START  */}
                    <Typography
                      variant="h6"
                      component="div"
                      color={colorText50}
                      sx={{ mb: 5 }}
                      fontWeight={600}
                    >
                      {textLine1}
                    </Typography>
                    {/*  DESKTOP CODE VALID TO END  */}
                    {/* DESKTOP MAIN TEXT START  */}
                    <Typography
                      variant="h1"
                      component="h2"
                      color={colorPrimary700}
                      sx={{
                        fontSize: { xs: "3.8rem", md: "4.3em" },
                        fontWeight: 700,
                        lineHeight: 1.1,
                        mb: 5,
                      }}
                    >
                      <Box
                        component={"span"}
                        dangerouslySetInnerHTML={{
                          __html: `${textLine2} ${textLine3} ${textLine4}`,
                        }}
                      />
                      {discountCode && discountCode !== "" && (
                        <Tooltip
                          title={
                            isCopied
                              ? t("main-page-slider-code-copied")
                              : t("main-page-slider-code-to-copy")
                          }
                        >
                          <Box
                            onClick={copyToClipboard}
                            sx={{
                              display: "inline-block",
                              alignItems: "center",
                              alignContent: "center",
                              borderBottomStyle: "dashed",
                              borderWidth: "3px",
                              borderColor: colorText50,
                              color: colorText50,
                            }}
                          >
                            <Box component={"span"} sx={{ ml: 4, mr: 2 }}>
                              {discountCode}
                            </Box>
                            <Box sx={{ display: "inline-block" }}>
                              <ScissorsIcon fill={colorText50} />
                            </Box>
                          </Box>
                        </Tooltip>
                      )}
                    </Typography>
                  </Box>
                  {/* DESKTOP MAIN TEXT END */}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent={
                    buttonText && buttonURL ? "space-between" : "flex-end"
                  }
                  alignItems="flex-end"
                >
                  <Button
                    endIcon={<ArrowOutwardIcon />}
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    className={"fcx-pt-5 fcx-pb-10 fcx-px-40 buttonSlider"}
                    href={buttonURL}
                    sx={{
                      backgroundColor: colorPrimary700,
                      color: "white",
                      "&:hover": {
                        backgroundColor: alpha(colorPrimary700, 0.9),
                      },
                    }}
                  >
                    {buttonText}
                  </Button>
                  <SlideNavigation
                    itemNumber={itemNumber}
                    elementsCount={elementsCount}
                  />
                </Stack>
              </Box>
              {/* *** DESKTOP view end *** */}
              {/* *** MOBILE view start *** */}
              <Box
                sx={{
                  display: { xs: "block", sm: "none" },
                  flexDirection: "column",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                  height: "100vh",
                }}
              >
                {/*  MOBILe CODE VALID TO START*/}
                <Box mb={7}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color={colorText50}
                    sx={{
                      borderRadius: "100px",
                      p: "10px 10px 10px 10px",
                      lineHeight: 1,
                    }}
                  >
                    {textLine1}
                  </Typography>
                </Box>
                {/* MOBILe CODE VALID TO END  */}
                <Typography
                  variant="h1"
                  component="h2"
                  className={"fcx-mb-40"}
                  fontSize={"2.6rem"}
                  fontWeight={700}
                  color={colorPrimary700}
                >
                  <Box
                    component={"span"}
                    dangerouslySetInnerHTML={{
                      __html: `${textLine2} ${textLine3} ${textLine4}`,
                    }}
                  />
                  {discountCode && discountCode !== "" && (
                    <Tooltip
                      title={
                        isCopied
                          ? t("main-page-slider-code-copied")
                          : t("main-page-slider-code-to-copy")
                      }
                    >
                      <Box
                        onClick={copyToClipboard}
                        sx={{
                          display: "inline-block",
                          alignItems: "center",
                          alignContent: "center",
                          borderBottomStyle: "dashed",
                          borderWidth: "3px",
                          borderColor: colorText50,
                          color: colorText50,
                        }}
                      >
                        <Box component={"span"} sx={{ ml: 4 }}>
                          {discountCode}
                        </Box>{" "}
                        <Box sx={{ display: "inline-block" }}>
                          <ScissorsIcon />
                        </Box>
                      </Box>
                    </Tooltip>
                  )}
                </Typography>

                <Button
                  sx={{
                    backgroundColor: colorPrimary700,
                    color: "white",
                    "&:hover": {
                      backgroundColor: alpha(colorPrimary700, 0.9),
                    },
                  }}
                  endIcon={<ArrowOutwardIcon />}
                  variant="contained"
                  disableElevation={true}
                  className={"fcx-pt-5 fcx-pb-10 fcx-px-20 buttonSliderMobile"}
                  href={buttonURL}
                >
                  {buttonText}
                </Button>
              </Box>
              {/* MOBILE NAVIGATION START */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "2rem",
                  alignContent: "center",
                  width: "100%",
                  marginLeft: "-40px",
                  display: { xs: "block", sm: "none" },
                }}
              >
                <SlideNavigation
                  itemNumber={itemNumber}
                  elementsCount={elementsCount}
                />
              </Box>
              {/* MOBILE NAVIGATION START */}
              {/* *** MOBILE view end *** */}
            </>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default ABSliderBox;
