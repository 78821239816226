"use client";
import { FC } from "react";

import Image from "next/image";

import { useRouter } from "nextjs-toploader/app";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { colorPrimary700, colorText600, colorText800 } from "theme/colors";
import { DotIcon } from "theme/SvgIcons";

type ArticleMainBoxPropsType = {
  title: string;
  image: string;
  url: string;
  categoryName: string;
  categoryHref: string;
  date: string;
};

const ArticleMainBox: FC<ArticleMainBoxPropsType> = ({
  title,
  image,
  url,
  categoryName,
  categoryHref,
  date,
}) => {
  const router = useRouter();
  return (
    <>
      <Box
        minHeight="250px"
        sx={{ mb: 5, height: "250px", position: "relative", cursor: "pointer" }}
      >
        <Image
          title={title}
          alt={title}
          src={image}
          fill
          style={{ objectFit: "cover" }}
          sizes="(max-width: 760px) 150vw, (max-width: 960px) 100vw, (max-width: 1400px) 50vw, 33vw"
          onClick={() => {
            router.push(url);
          }}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Link
          href={categoryHref}
          color={colorPrimary700}
          variant="body2"
          title={categoryName}
        >
          {categoryName}
        </Link>
        <DotIcon />
        <Typography color={colorText600} variant="body2">
          {date}
        </Typography>
      </Stack>
      <Link
        href={url}
        variant="subtitle1"
        fontWeight={600}
        sx={{
          color: colorText800,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none",
          },
        }}
        title={title}
      >
        {title}
      </Link>
    </>
  );
};

ArticleMainBox.propTypes = {};

export default ArticleMainBox;
