"use client";
import { FC } from "react";

import { A11y, Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { CurrencyType } from "types/QuickCartTypes";

import { mockVariant } from "components/AbTestDiscountVsInstallments/data";
import ABSlider from "components/AbTestDiscountVsInstallments/slider/ABSlider";
import SlideVideo from "components/MainCarousel/SlideVideo";
import SlidePromotion from "components/MainCarousel/SlidePromotion";
import SlideClassic from "components/MainCarousel/SlideClassic";

interface MainCarouselProps {
  currency: CurrencyType;
  abTest?: abTestType;
  sliderTop: {
    interval: number;
    slides: {
      template: string;
      header: string;
      image: string;
      imageMobile: string;
      imageAlt?: string;
      button: string;
      buttonUrl: string;
      promotionCode: string;
      promotionValidFor: string;
      promotionPriceFrom: string;
    }[];
  };
}

const MainCarousel: FC<MainCarouselProps> = ({
  sliderTop,
  currency,
  abTest,
}) => {
  const isABTest = abTest || mockVariant;
  const elementsCount = isABTest
    ? sliderTop?.slides?.length + 1
    : sliderTop?.slides?.length;

  const itemNumber = (i: number) => {
    return isABTest ? i + 2 : i + 1;
  };

  return (
    <Swiper
      modules={[EffectFade, Autoplay, A11y]}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: sliderTop.interval,
        disableOnInteraction: false,
      }}
      effect={"fade"}
      style={{ position: "relative", zIndex: 0 }}
    >
      {isABTest && (
        <SwiperSlide key={`slider-0`}>
          <ABSlider variantAB={abTest?.variant} elementsCount={elementsCount} />
        </SwiperSlide>
      )}
      {sliderTop.slides !== null &&
        sliderTop.slides
          .filter((b) => b.image)
          .map((b, i) => {
            return (
              <SwiperSlide key={`slider-${i}`}>
                {b.template && b.template === "VIDEO" && <SlideVideo />}
                {b.template && b.template === "PROMOTION" && (
                  <SlidePromotion
                    header={b.header}
                    buttonUrl={b.buttonUrl}
                    button={b.button}
                    image={b.image}
                    imageMobile={b.imageMobile}
                    imageAlt={b.imageAlt}
                    elementsCount={elementsCount}
                    itemNumber={itemNumber(i)}
                    code={b.promotionCode}
                    priceFrom={b.promotionPriceFrom}
                    validateTo={b.promotionValidFor}
                    currency={currency}
                  />
                )}
                {(!b.template || b.template === "CLASSIC") && (
                  <SlideClassic
                    header={b.header}
                    buttonUrl={b.buttonUrl}
                    button={b.button}
                    image={b.image}
                    imageMobile={b.imageMobile}
                    imageAlt={b.imageAlt}
                    elementsCount={elementsCount}
                    itemNumber={itemNumber(i)}
                  />
                )}
              </SwiperSlide>
            );
          })}
    </Swiper>
  );
};
export default MainCarousel;
